import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';
import { GOOGLE_API_KEY, MAP_ZOOM, MAP_CENTER, REQUEST_DATA_INTERVAL } from './config';
import PinWh from './assets/img/pin_wh.svg';
import PinOrder from './assets/img/pin_order.svg';

enum CourierStatus {
  OFFLINE = 0,
  ONLINE = 1,
  IDLE = 2,
  ASSIGNED_TO_JOB = 7,
  ACCEPTED_JOB = 8,
  PICKING_UP = 3,
  DELIVERING = 4,
  HEADING_TO_BASE = 5,
  NOT_RESPONDING = 6
}

enum VehicleType {
  BICYCLE = 0,
  CAR = 1,
  FOOTER = 2,
  MOTORBIKE = 3
}

enum TaskState {
  UNASSIGNED = 0,
  ASSIGNED_TO_JOB = 1,
  ON_THE_WAY = 2,
  ON_POINT = 3,
  COMPLETED = 4,
  FAILED = 5,
  CANCELED = 6
}

type Task = {
  commentary: string | null;
  complete_by_max: number | null;
  complete_by_min: number | null;
  completed_at: number | null;
  kind: string;
  start_by: number | null;
  started_at: number | null;
  state: TaskState;
}

type CourierTrackResponse = {
  data: {
    order_id: string;
    order_status: string;
    courier: {
      latitude: number;
      longitude: number;
      name: string;
      phone: string;
      status: CourierStatus;
      vehicle_type: VehicleType;
    }
    destination: {
      latitude: number;
      longitude: number;
      name: string;
    }
    warehouse: {
      latitude: number;
      longitude: number;
      name: string;
    }
    dropoff_task: Task | Record<string, never>;
    pickup_task: Task | Record<string, never>;
    return_task: Task | Record<string, never>;
  }
}

type MapPinProps = {
  lat: number;
  lng: number;
  children: JSX.Element;
}

const MapPin = ({ children }: MapPinProps) => children;

function App() {
  const [orderId, setOrderId] = useState('');
  const [warehouseCoords, setWarehouseCoords] = useState<GoogleMapReact.Coords | null>(null);
  const [courierCoords, setCourierCoords] = useState<GoogleMapReact.Coords | null>(null);
  const [orderCoords, setOrderCoords] = useState<GoogleMapReact.Coords | null>(null);
  const [vehicleType, setVehicleType] = useState<VehicleType>(
    VehicleType[VehicleType.BICYCLE as unknown as keyof typeof VehicleType]);
  const [mapApi, setMapApi] = useState<{ map: any, maps: any } | null>(null);

  useEffect(() => {
    setOrderId(new URLSearchParams(window.location.search).get('order_id') || '');
    //eslint-disable-next-line
  }, [window.location.search]);

  useEffect(() => {
    if (!orderId) return;
    let apiRoot = process.env.REACT_APP_API_ROOT
      ? process.env.REACT_APP_API_ROOT
      : 'https://api2.citydrinks.com/';
    if (!apiRoot.endsWith('/')) apiRoot += '/';
    const requestData = () => {
      window.fetch(`${apiRoot}delivery/couriers-track/${orderId.toUpperCase()}`, {
        body: null,
        method: 'GET',
      }).then((response) => response.json()).then(({ data }: CourierTrackResponse) => {
        if (!data) return Promise.reject();
        setWarehouseCoords({ lat: data.warehouse.latitude, lng: data.warehouse.longitude });
        setCourierCoords({ lat: data.courier.latitude, lng: data.courier.longitude });
        setOrderCoords({ lat: data.destination.latitude, lng: data.destination.longitude });
        setVehicleType(data.courier.vehicle_type);
      }).catch((error) => {
        setWarehouseCoords(null);
        setCourierCoords(null);
        setOrderCoords(null);
        setVehicleType(VehicleType[VehicleType.BICYCLE as unknown as keyof typeof VehicleType]);
        error && console.error(error);
      });
    };
    const interval = setInterval(() => {
      if (!orderId) return;
      requestData();
    }, REQUEST_DATA_INTERVAL);
    requestData();
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [orderId]);

  useEffect(() => {
    if (!mapApi || (!courierCoords && !orderCoords)) return;
    const bounds = new mapApi.maps.LatLngBounds();
    if (orderCoords) bounds.extend(orderCoords);
    if (courierCoords) bounds.extend(courierCoords);
    mapApi.map.fitBounds(bounds);
    //eslint-disable-next-line
  }, [mapApi, JSON.stringify(courierCoords), JSON.stringify(orderCoords)]);

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: GOOGLE_API_KEY,
        language: 'en-GB',
        region: 'GB',
      }}
      defaultCenter={MAP_CENTER}
      defaultZoom={MAP_ZOOM}
      options={{
        clickableIcons: false,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
      }}
      shouldUnregisterMapOnUnmount={true}
      onGoogleApiLoaded={setMapApi}
    >
      {warehouseCoords !== null && (
        <MapPin lat={warehouseCoords.lat} lng={warehouseCoords.lng}>
          <img className="map-pin _wh" src={PinWh} alt="" />
        </MapPin>
      )}
      {orderCoords !== null && (
        <MapPin lat={orderCoords.lat} lng={orderCoords.lng}>
          <img className="map-pin _order" src={PinOrder} alt="" />
        </MapPin>
      )}
      {courierCoords !== null && (
        <MapPin lat={courierCoords.lat} lng={courierCoords.lng}>
          <div className={`map-pin _courier _${String(vehicleType).toLowerCase()}`} />
        </MapPin>
      )}
    </GoogleMapReact>
  );
}

export default App;
